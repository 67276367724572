<template>
  <div>
    <Card>
      <template #header>
        <div class="d-flex flex-column w-100">
          <h4 class="my-6 mx-4">İlçeler</h4>
          <div class="row align-items-center">
            <div class="col-md-8 col-12">
              <MultiSelect
                v-model="county"
                :options="counties"
                :multiple="false"
                label="İl Seçiniz"
                placeholder="İl Seçiniz"
                id="il"
              />
            </div>
            <div class="col-md-4 col-12 mt-7">
              <Input placeholder="Ara.." v-model="search" class="mr-2" />
            </div>
          </div>
        </div>
      </template>

      <List
        v-if="districts"
        :header="header"
        :data="items"
        :pagination="pagination"
        :loading="loading"
        @updatePage="updatePage"
        moduleName="İlçeler Listesi"
        :moduleCreateEvent="true"
        :moduleSearch="search"
      >
      </List>

      <p v-else>Lütfen il Seçiniz</p>
    </Card>
  </div>
</template>
<script>
import { DISTRICTS } from "@/core/services/store/counties.module.js";
import { mapGetters } from "vuex";

export default {
  name: "systemUsers",
  data() {
    return {
      addNewNote: "",
      addFileModal: false,
      addFileModal2: false,
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      header: [
        { text: "İlçe Adı", value: "ilce_adi" },
        { text: "İl Adı", value: "il_adi" },
        { text: "Şehir Kodu", value: "il_id", size: "100px" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",
      row: [],
      districts: [],
      counties: [],
      county: {},
    };
  },

  created() {
    this.getList();
    this.counties = this.setCountiesSelectValues(this.getCounties);
  },
  methods: {
    getList() {
      this.loading = true;

      this.totalItems = 3;
      this.pagination = {
        current_page: 1,
        total_pages: 9,
        total_items: 3,
      };
      if (this.county?.value) {
        this.$store.dispatch(DISTRICTS, this.county.value).then((response) => {
          this.items = response;

          if (this.search) {
            this.items = this.items.filter(item => {
              for (const prop in item) {
                if(item[prop].toString().toLocaleLowerCase('tr').includes(this.search)) {
                  return true;
                }
              }
              return false;
            });
          }
        });
      } else {
        this.items = [];
      }
      this.loading = false;

      // this.items = [];
      // this.totalItems = 0;
      // let queryLimit = this.perPage ? this.perPage : 10;
      // let query = `user/list?Page=${
      //   this.page ? this.page : 1
      // }&limit=${queryLimit}`;
      // if (this.search) {
      //   query += `&search=${this.search}`;
      // }
      // this.$dbFunctions
      //   .get(query)
      //   .then((res) => {
      //     if (res.success == true && res.count > 0) {
      //       this.items = res.data;
      //       this.totalItems = res.count;
      //       this.pagination = {
      //         current_page: res.page,
      //         total_pages: res.pageCount,
      //         total_items: res.count,
      //       };
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   })
      //   .finally(() => {
      //     this.loading = false;
      //   });
    },

    setCountiesSelectValues(county) {
      const newCounties = county.map((index, key) => {
        return { text: index.il_adi, value: index.il_id };
      });
      return newCounties;
    },

    updatePage(value) {
      this.meta = value;
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
    county() {
      this.getList();
    },
  },
  computed: {
    ...mapGetters(["getCounties"]),
  },
};
</script>
